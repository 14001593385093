import React, { Component } from 'react';
import { withFirebase } from '../components/Firebase/FirebaseContext';
import { withTrainings } from '../BuisenessLogic/TrainingsContext';
import Layout from '../components/layout';
import styled from 'styled-components';

const Wrapper = styled.div`
  * {
    color: white;
    border-color: white !important;
  }
`;

class Contacts extends Component {
  render() {
    return <Wrapper />;
  }
}

const LandingPageWithFirebase = withFirebase(withTrainings(Contacts));

export default () => (
  <Layout>
    <LandingPageWithFirebase />
  </Layout>
);
